import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import { OlBaseLayerProps } from "./OlLayers";

export interface BlankLayerProps extends OlBaseLayerProps {
}
 
export const createTiledWmsLayer = (extent: number[], url: string, layerName: string, title?: string, style?: string) : BaseLayer => {
  const wmsSource = new TileWMS({
    url,
    params: {
      'LAYERS': layerName,
      'TILED': true,
      'STYLES': style
    },
  });

  const layer: BaseLayer = new TileLayer({
      extent,
      source: wmsSource,
      properties: {
        title
      }
    });  
  return layer
}


const BlankLayer = (_: BlankLayerProps) => {          
    
    return (<div>      
    </div>);
  };
  
  export default BlankLayer