import React, { ReactElement, useEffect, useRef } from 'react'
import TileLayer from 'ol/layer/Tile'
import XYZSource from 'ol/source/XYZ'
import 'ol/ol.css'
import { Map, MapBrowserEvent } from 'ol'
import { Coordinate } from 'ol/coordinate'
import { toLonLat } from 'ol/proj'

export interface OlBaseLayerProps {
  map?: Map;
  title?: string;
}

export interface OlTileLayerProps extends OlBaseLayerProps {
  url: string
  onClick?: (coords: Coordinate) => void
}

export const OlTileLayer = (props: OlTileLayerProps) => {
  const { map, url, onClick } = props  
  const tileLayerRef = useRef<TileLayer<XYZSource>>(
    new TileLayer({
      source: new XYZSource({
        url: url,        
      })
    })
  )
  useEffect(() => {
    const tileLayer = tileLayerRef.current
    if (tileLayer) {
      map?.addLayer(tileLayer)
    }
    return () => {
      map?.removeLayer(tileLayer)
    }
  }, [map, tileLayerRef])

  useEffect(() => {
    if (!map || !onClick) {
      return
    }
    const clickHandler = function (e: MapBrowserEvent<any>) {
      onClick(toLonLat(e.coordinate))
    }
    map.on('click', clickHandler)
    return () => map.un('click', clickHandler)
  }, [onClick, map])

  return <div></div>
}

const OlLayers = (props: { children: ReactElement | ReactElement[]; map?: Map }) => {
  const { children, map } = props
  const _children: ReactElement[] = Array.isArray(children) ? children : [children]
  if (!map) {
    return null
  }
  return (
    <React.Fragment>
      {_children.map((c: ReactElement, idx: number) => {
        return React.createElement(c.type, { ...c.props, map: map, key: idx })
      })}
    </React.Fragment>
  )
}

export default OlLayers
