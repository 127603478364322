import { useEffect, useRef } from "react";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import { OlBaseLayerProps } from "./OlLayers";

export interface OlWmsTiledLayerProps extends OlBaseLayerProps {
    visible: boolean;
    extent: number[],
    url: string,
    layer: string,
    styleName?: string
}
 
export const createTiledWmsLayer = (extent: number[], url: string, layerName: string, title?: string, style?: string) : BaseLayer => {
  const wmsSource = new TileWMS({
    url,
    params: {
      'LAYERS': layerName,
      'TILED': true,
      'STYLES': style
    },
  });

  const layer: BaseLayer = new TileLayer({
      extent,
      source: wmsSource,
      properties: {
        title
      }
    });  
  return layer
}


const OlWmsTiledLayer = (props: OlWmsTiledLayerProps) => {  
    const { map, visible, extent, url, layer, title, styleName } = props;
    const layerRef = useRef(createTiledWmsLayer(extent, url, layer, title, styleName ));    

    useEffect(() => {
      const layer = layerRef.current
      if (layer) {
        map?.addLayer(layer);
      }
      return () => { map?.removeLayer(layer) }
    }, [map, layerRef]);
   
    useEffect(() => {
      if (!layerRef.current) {
          return;
      }
      const layer = layerRef.current
      layer.setVisible(visible);
      layer.changed();
    }, [visible, layerRef]);  
            
    return (<div>      
    </div>);
  };
  
  export default OlWmsTiledLayer