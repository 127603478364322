import { memo } from "react";

import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { FeatureLike } from "ol/Feature";

import OpenLayersMap from "../ol/OpenLayersMap";
import useWindowSize from "../util/useWindowSize";
import OlWmtsLayer from "../ol/OlWmtsLayer";
import OlUtmView, { epsg25832 } from "../ol/OlUtmView";
import SkaermkortDaempetSource from "./SkaermkortDaempetSource";
import SkaermkortGraaSource from './SkaermkortGraaSource'
import SkaermkortOrtoForaarSource from "./SkaermkortOrtoForaarSource";
import { extent } from "../util/kortforsyning";
import OlVectorLayer from "../ol/OlVectorLayer";
import OlStaticSource from "../ol/OlSource";

import OlWmsTiledLayer from "../ol/OlWmsTiledLayer";

import OlLayerSelector from "../ol/controls/OlLayerSelector";
import OlLayerGroup from "../ol/controls/OlLayerGroup";
import OlLayers from "../ol/OlLayers";

import BlankLayer from "../ol/BlankLayer";

export interface SmaabiotopMapProps {
    biotoper: any[]
    onSelectedBiotopChanged: (f : (FeatureLike | null)) => void    
}

const toFc = (biotoper: any) => {
  if (!biotoper) {
    return {
      type: "FeatureCollection",
      features: []
    }
  }
  return {
    type: "FeatureCollection",
    features: biotoper.features.map((f: any) => ({
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: f.geometry.rings,
      },
      properties: f.attributes,
    })),
  };
};

const polygonStyle = new Style({
  stroke: new Stroke({
    width: 2.5,
    color: "rgb(255, 128, 128)",
  }),
  fill: new Fill({ color: "rgba(196,128,128,0.2)" }),
});
const dk = [402535, 6017299, 919204, 6460999];

function SmaabiotopMap(props: SmaabiotopMapProps) {

  const { biotoper, onSelectedBiotopChanged } = props
  
  const size = useWindowSize();
  if (!size.height || !size.width) {
    return null;
  }
  let mapHeight = size.height - 16;
  if (size.width < 1000) {
    mapHeight = 400;
  }

  return (
    <>
      <OpenLayersMap id="smaabiotop-map" left={0} right={0} height={mapHeight}>
        <OlUtmView bounds={dk} extent={extent} projection={epsg25832} />        
        <OlLayerSelector>
          <OlLayerGroup title='Skærmkort'>
            <OlWmtsLayer title='Dæmpet' opacity={1} type="base">
              <SkaermkortDaempetSource />
            </OlWmtsLayer>
            <OlWmtsLayer title='Grå' opacity={1} type="base">
              <SkaermkortGraaSource />
            </OlWmtsLayer> 
            <OlWmtsLayer title='Ortofoto seneste' opacity={1} type="base">
              <SkaermkortOrtoForaarSource />
            </OlWmtsLayer>
          </OlLayerGroup>
          <OlLayerGroup title='Overlay'>
            <BlankLayer
              title='Intet valgt'              
            />
            <OlWmsTiledLayer
              title='Bioscore'
              visible={true}
              url="https://miljoegis.mim.dk/wms?service=WMS&servicename=miljoegis-plangroendk_wms"
              layer="biodiversitet-2021_bioscore"
              extent={[80600, 5998000, 1124900, 6513700]}
            />
            <OlWmsTiledLayer
              title='Beskyttede naturtyper'
              visible
              url="https://arealeditering-dist-geo.miljoeportal.dk/geoserver/ows"
              layer="dai:bes_naturtyper"
              styleName="dai:bes_naturtyper"
              extent={[
                9.60500000044703, 55.8100000005215, 892786.7321, 6402158.6053,
              ]}
            />            
          </OlLayerGroup>
        </OlLayerSelector>      
        <OlLayers>
          <OlVectorLayer
            onFeatureClicked={(features: FeatureLike[]) => {
              if (features.length >= 1) {
                onSelectedBiotopChanged(features[0]);
              }
            }}
            visible
            style={polygonStyle}
          >
            <OlStaticSource
              sourceProjection="EPSG:25832"
              targetProjection="EPSG:25832"
              featureCollection={toFc(biotoper)}
            />
          </OlVectorLayer>
        </OlLayers>
      </OpenLayersMap>
    </>
  );
}

export default memo(SmaabiotopMap);
