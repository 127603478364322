import { useEffect } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Projection } from "ol/proj";


export const epsg25832 = new Projection({
  code: 'EPSG:25832',
  units: 'm',
  global: false
})

const OlUtmView = (props: {
  bounds: number[];
  extent: number[];
  projection: Projection;
  map?: Map;
}) => {
  const { map, bounds, extent, projection } = props;
  useEffect(() => {
    if (map) {
      map?.setView(new View({
        projection: projection,
        extent: extent
      }))
      map.getView().fit(bounds);
    }    
  }, [map, bounds, extent, projection]);

  return null;
};

export default OlUtmView;
