import { useCallback, useEffect, useState } from "react";
import { FeatureLike } from "ol/Feature";

import SmaabiotopInfobox from "../components/SmaabiotopInfobox";

import BiotopService from '../data/biotopService'
import SmaabiotopMap from "../components/SmaabiotopMap";

export interface MapPageProps {}


function MapPage(props: MapPageProps) {
  const [ biotoper, setBiotoper ] = useState<any>()
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike | null>(
    null
  );

  const onSelectedBiotopChanged = useCallback(biotop => {
    setSelectedFeature(biotop)
  },[]);
  
  useEffect(() => {
    BiotopService.fetchBiotoper().then(data => setBiotoper(data))
  }, [ setBiotoper])

  return (
    <>
      <SmaabiotopMap biotoper={biotoper} onSelectedBiotopChanged={onSelectedBiotopChanged} />
      <SmaabiotopInfobox
        properties={
          selectedFeature ? selectedFeature.getProperties() : undefined
        }
        onClose={() => setSelectedFeature(null)}
      />
    </>
  );
}

export default MapPage;
