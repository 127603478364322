import { Button, Box, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

export interface SmaabiotopProperties {
    geometry?: any;
    Areal?: string;
    Mark?: string;
    Eng?: string;
    Hede?: string;
    Mose?: string;
    Overdrev?: string;
    Strandeng?: string;
    Skov?: string;
    Andet?: string;
    Kommune?: string;
    Soe?: string;
    MarkblokNr?: string;
    bioscore?: number;
    biotopId?: number;
    "Ikke-nullet Bioscore"?: string;
}

export interface SmaabiotopInfoboxProps {
    properties?: SmaabiotopProperties
    onClose: () => void
}

export interface LabelProps {
    label: string;
    value?: string | number | boolean | null
}

const Label = ({ label, value } : LabelProps) => {
    if (value === null || typeof (value) === 'undefined') {
        return null
    } else {
        return  <Typography>{label}: {value}</Typography>
    }
}

const SmaabiotopInfobox = ({ properties, onClose } : SmaabiotopInfoboxProps) => {
    const p = properties || {}
    if (p) {
        delete p.geometry
    }
    return (
        <Dialog onClose={() => onClose()} open={Boolean(properties)}>
            <DialogTitle>BiotopId: {p.biotopId}</DialogTitle>
            <Box sx={{ paddingTop: 0, paddingLeft: 2, paddingRight: 2, paddingBottom: 2}}>
                <Label label='Areal' value={p.Areal} />
                <Label label='Mark' value={p.Mark} />
                <Label label='Eng' value={p.Eng} />
                <Label label='Hede' value={p.Hede} />
                <Label label='Mose' value={p.Mose} />
                <Label label='Overdrev' value={p.Overdrev} />
                <Label label='Strandeng' value={p.Strandeng} />
                <Label label='Skov' value={p.Skov} />
                <Label label='Andet' value={p.Andet} />
                <Label label='Sø' value={p.Soe} />
                <Label label='Bioscore' value={p.bioscore} />            
                <Label label='Ikke-nullet bioscore' value={p["Ikke-nullet Bioscore"]} />
                <Label label='Markblok nr' value={p["MarkblokNr"]} />
                <Label label='Kommune' value={p.Kommune} />
                <Button size='small' variant='contained' color='inherit' fullWidth onClick={() => onClose()}>Luk</Button>
            </Box>
        </Dialog>
    )    
}






















export default SmaabiotopInfobox