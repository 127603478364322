import TileLayer from "ol/layer/Tile";
import OlWmtsSource from "../ol/OlWmtsSource";
import {
  attributionText,
  ortoTileGrid,
  ortoForaar,
} from "../util/kortforsyning";
import { WMTS } from "ol/source";


const SkaermkortOrtoForaarSource = ({ layer }: { layer?: TileLayer<WMTS> }) => {
  return (
    <OlWmtsSource
      layer={layer}
      matrixSet="KortforsyningTilingDK"
      layerName="orto_foraar_wmts"
      url={ortoForaar()}
      tilegrid={ortoTileGrid}
      attributionText={attributionText}
    />
  );
};

export default SkaermkortOrtoForaarSource;
