import React from 'react';
import ReactDOM from 'react-dom';
import es6Promise from 'es6-promise';
import 'whatwg-fetch'
import MapPage from './pages/MapPage';

export interface SmaabiotopConfig {
  version: string;
  dataUrl: string;
}

declare const SMAABIOTOP_CONFIG : SmaabiotopConfig;

es6Promise.polyfill();

console.log({ config: SMAABIOTOP_CONFIG })

ReactDOM.render(
  <React.StrictMode>
      <MapPage />
  </React.StrictMode>,
  document.getElementById('root')
);
