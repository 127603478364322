import React, { CSSProperties, ReactElement, useState } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import { Map } from "ol";
import { OlLayerGroupProps } from "./OlLayerGroup";

// Render all groups - pass map as prop

// Render layer-switch control

export interface OlLayerSelectorProps {
  map?: Map;
  children: ReactElement<OlLayerGroupProps> | ReactElement<OlLayerGroupProps>[];
}

const createStyle = (position: any): CSSProperties => ({
  padding: 10,
  borderRadius: 3,
  borderColor: "#ccc",
  borderStyle: "solid",
  borderWidth: 1,
  backgroundColor: "rgba(240, 240, 240, 0.8)",
  position: "fixed",
  cursor: "pointer",
  bottom: position.bottom + 10,
  left: position.left + 10,
  transition: "all 1s",
});

const OlLayerSelector = ({ map, children }: OlLayerSelectorProps) => {
  const [expanded, setExpanded] = useState(false);
  const style = createStyle({ left: 0, bottom: 0 });
  const _children: ReactElement<OlLayerGroupProps>[] = Array.isArray(children)
    ? children
    : [children];

  if (!map) {
    return null;
  }
  return (
    <div
      style={style}
      onClick={() => {
        if (!expanded) {
          setExpanded(true);
        }
      }}
    >
      {_children.map((c: ReactElement, idx: number) => {
        return React.createElement(c.type, {
          ...c.props,
          expanded,
          map: map,
          key: idx,
        });
      })}
      <div onClick={() => setExpanded(!expanded)}>
        <LayersIcon />
      </div>
    </div>
  );
};

export default OlLayerSelector;
