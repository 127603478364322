import React, { useEffect, useRef, ReactElement } from "react";
import { MapBrowserEvent } from "ol";
import { OlFeature } from "./OpenLayersMap";
import BaseLayer from "ol/layer/Base";
import TileLayer from 'ol/layer/Tile';
import { OlBaseLayerProps } from "./OlLayers";

export interface OlWmtsLayerProps extends OlBaseLayerProps {
    visible?: boolean;
    opacity: 1.0,
    type: 'base',      
    children: ReactElement;
    onFeatureClicked?: (features: OlFeature[]) => void;
}
 
export const createWmtsLayer = (opacity: number, visible: boolean, title?: string) : BaseLayer => {
  const layer: BaseLayer = new TileLayer({
      opacity,
      visible,
      properties: { 
        title
      }
    });  
  return layer
}

export const createVisibleLayer = (opacity: number, title?: string) : BaseLayer => {
  return createWmtsLayer(opacity, true, title);
}

const OlWmtsLayer = (props: OlWmtsLayerProps) => {
    //const requestRef = useRef<number>();
  
    const { onFeatureClicked, map, opacity, title, visible } = props;
    const layerRef = useRef(createWmtsLayer(opacity, Boolean(visible), title));

    useEffect(() => {
      const layer = layerRef.current
      if (layer) {
        map?.addLayer(layer);
      }
      return () => { map?.removeLayer(layer) }
    }, [map, layerRef]);
   
    useEffect(() => {
      if (!map || !onFeatureClicked) {
        return
      }
      const clickHandler = function (e: MapBrowserEvent<any>) {
        const layer = layerRef.current
          const features: OlFeature[] = [];
          map.forEachFeatureAtPixel(e.pixel, function (feature, _layer: any) {
            if (_layer === layer) {
              features.push(feature);
            }
          });
          if (features.length > 0) {
            onFeatureClicked(features);
          }
        }
        map.on("click", clickHandler);
      return () => map.un("click", clickHandler)
    }, [ onFeatureClicked, layerRef, map])
  
    useEffect(() => {
      if (!layerRef.current) {
          return;
      }
      const layer = layerRef.current
      layer.setVisible(Boolean(visible));
      layer.changed();
    }, [visible, layerRef]);  
        
    const children: ReactElement = props.children;
    return (<div>
       {React.createElement(children.type, { ...children.props, layer: layerRef.current })}
    </div>);
  };
  
  export default OlWmtsLayer