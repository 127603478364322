import React, { useEffect, useState, ReactElement } from "react";
// Render layer-buttons for all layers in group
// Control layer-visibility

import { Map } from "ol";

import { OlBaseLayerProps } from "../OlLayers";

import {
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Layer } from "ol/layer";

const LayerButton = ({
  layer,
  selected,
  onClick,
}: {
  layer: string;
  selected: boolean;
  onClick: (layer: string) => void;
}) => {
  return (
    <FormControlLabel
      key={layer}
      value={layer}
      control={
        <Radio
          onClick={() => onClick(layer)}
          checked={selected}
          color="primary"
        />
      }
      label={
        <Typography variant="subtitle2" color="textPrimary">
          {layer}
        </Typography>
      }
    />
  );
};

export interface OlLayerGroupProps {
  map?: Map;
  expanded?: boolean;
  title: string;
  children: ReactElement<OlBaseLayerProps> | ReactElement<OlBaseLayerProps>[];
}

const OlLayerGroup = ({
  map,
  children,
  title,
  expanded,
}: OlLayerGroupProps) => {
  const _children: ReactElement<OlBaseLayerProps>[] = Array.isArray(children)
    ? children
    : [children];

    const [ layerNames, setLayerNames ] = useState<string[]>([])
    const [ legendUrl, setLegendUrl ] = useState<string | undefined>()

  const [selectedLayer, setSelectedLayer] = useState<string | null>(
    layerNames[0]
  );

  useEffect(() => {    
    const _children: ReactElement<OlBaseLayerProps>[] = Array.isArray(children) ? children : [children];
    const layerNames = _children.map((c) => c.props.title || "No title");
    setLayerNames(layerNames)
    const defaultLayer = layerNames[0]    
    setSelectedLayer(defaultLayer)
  }, [children, setLayerNames, setSelectedLayer])

  useEffect(() => {
    if (!map) {
        return
    }

    // If selected layer is a blank layer it is not in the map layers. Set legend to undefined
    const l = map.getLayers().getArray().find(l => selectedLayer === l.getProperties().title)
    if (!l) {
      setLegendUrl(undefined)      
    }
    map.getLayers().getArray().filter(l => Boolean(layerNames.find(ln => ln === l.getProperties().title))).forEach(l => {
        if (l.getProperties().title === selectedLayer) {
          const source = (l as Layer).getSource && (l as Layer).getSource()          
          const legendUrl = source && (source as any).getLegendUrl ? (source as any).getLegendUrl() : undefined      
          setLegendUrl(legendUrl)
          l.setVisible(true)
        } else {
            l.setVisible(false)
        }
        if (!selectedLayer) {
          setLegendUrl(undefined)
        }
    })
  }, [map, layerNames, selectedLayer])

  console.log({ selectedLayer, legendUrl})

  if (!map) {
    return null;
  }
  return (
    <>
      { expanded &&
      <div style={{ marginBottom: 8 }}>
        <FormControl>
          <FormLabel color="primary" component="legend">
            {title}
          </FormLabel>
          <RadioGroup>
            {layerNames.map((layer, idx) => (
              <LayerButton
                key={`${layer}_${idx}`}
                layer={layer}
                selected={layer === selectedLayer}
                onClick={() =>
                  layer === selectedLayer
                    ? setSelectedLayer(null)
                    : setSelectedLayer(layer)
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>}
      {_children.map((c: ReactElement, idx: number) => {
        return React.createElement(c.type, { ...c.props, map: map, key: idx });
      })}

      { legendUrl && <div style={{ 
          backgroundColor: 'rgba(240, 240, 240, 0.8)', 
          padding: '10px',
          paddingBottom: '4px', 
          paddingRight: '20px', 
          'borderRadius': '3px', 
          'borderColor': 'rgb(204, 204, 204)', 
          'borderStyle': 'solid',
         'borderWidth': '1px', 
         position: 'fixed', 
         right: '25px', 
         bottom: '50px' }}><img alt='legend' src={legendUrl} /></div>}
    </>
  );
};

export default OlLayerGroup;
