import TileLayer from "ol/layer/Tile";
import OlWmtsSource from "../ol/OlWmtsSource";
import {
  attributionText,
  dfTileGrid,
  skaermkortGraa,
} from "../util/kortforsyning";
import { WMTS } from "ol/source";

const SkaermkortGraaSource = ({ layer }: { layer?: TileLayer<WMTS> }) => {
  return (
    <OlWmtsSource
      layer={layer}
      matrixSet="View1"
      layerName="topo_skaermkort_graa"
      url={skaermkortGraa()}
      tilegrid={dfTileGrid}
      attributionText={attributionText}
    />
  );
};

export default SkaermkortGraaSource;
