import { SmaabiotopConfig } from "..";

declare const SMAABIOTOP_CONFIG: SmaabiotopConfig;


class BiotopService {

    dataUrl(): string {
        return SMAABIOTOP_CONFIG.dataUrl
    }

    async fetchBiotoper(): Promise<any> {
        const res = await fetch(this.dataUrl())
        const json = await res.json()
        return json
    }
}

const instance = new BiotopService()

export default instance