import { useEffect, useRef } from "react"

//import { Layer } from "ol/layer"
import TileLayer from "ol/layer/Tile";
import { WMTS } from "ol/source";
import WMTSTileGrid from 'ol/tilegrid/WMTS';


const createStaticWmtsSource = (layerName: string, matrixSet: string, tilegrid: WMTSTileGrid, attributionText : (string | undefined), url : string) => {
    return new WMTS({
        attributions: attributionText,
        url,
        layer: layerName,
        matrixSet,
        format: "image/jpeg",
        tileGrid: tilegrid,
        style: 'default'
    })
}

export interface OlWmtsSourceProps {
    layer?: TileLayer<WMTS>;
    layerName: string
    matrixSet: string    
    tilegrid: WMTSTileGrid;
    attributionText?: string
    url: string
}

const OlWmtsSource = ({ layer, tilegrid, layerName, matrixSet, attributionText, url }: OlWmtsSourceProps) => {
    const sourceRef = useRef<WMTS>(createStaticWmtsSource(layerName, matrixSet, tilegrid, attributionText, url))

    useEffect(() => {
        layer?.setSource(sourceRef.current)
    }, [ layer, sourceRef])

    return (<div></div>)
}

export default OlWmtsSource